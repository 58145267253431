<template>
	<div class="ad-main-c">
		<div class="main">
			<el-card>
				<div class="marB10 flex between">
					<p>出差申请单号：<span>{{dataDetail.ccsqdNo}}</span></p>
				</div>
				<div class="marB10 border flex h80">
					<div class="leftTitle">
						出差人信息
					</div>
					<div class="right-main flex">
						<div class="topItem">
							<span class="title-width">申请单：</span>
							<span>{{dataDetail.ccsqdNo}}</span>
						</div>
						<div class="topItem">
							<span class="title-width">申请状态：</span>
							<span v-if="dataDetail.status==0">草稿</span>
							<span v-if="dataDetail.status==1">审批中</span>
							<span v-if="dataDetail.status==2">审批通过</span>
							<span v-if="dataDetail.status==3">审批拒绝</span>
							<span v-if="dataDetail.status==4">已删除</span>
							<span v-if="dataDetail.status==5">报销中</span>
							<span v-if="dataDetail.status==6">已报销</span>
							<span v-if="dataDetail.status==7">已取消</span>
						</div>
						<div class="topItem">
							<span class="title-width">成本中心：</span>
							<span>{{dataDetail.costCenterName}}</span>
						</div>
						<div class="topItem">
							<span class="title-width">出差人：</span>
							<span>{{dataDetail.ccr}}</span>
						</div>
						<div class="topItem">
							<span class="title-width">随行人：</span>
							<span>{{dataDetail.sxr?dataDetail.sxr:''}}</span>
						</div>
						<div class="topItem">
							<span class="title-width">常驻地：</span>
							<span>{{dataDetail.mdd}}</span>
						</div>
						<div class="topItem">
							<span class="title-width">出差日期：</span>
							<span>
								<span>{{dataDetail.dateBegin}}</span>
								<span>至</span>
								<span>{{dataDetail.dateEnd}}</span>
							</span>
						</div>
						<div class="topItem">
							<span class="title-width">出差天数</span>
							<span>{{routeDiffTime(dataDetail)}}</span>
						</div>
						<div class="topItem">
							<span class="title-width">出差事由：</span>
							<span>{{dataDetail.manyMdd}}</span>
						</div>
					</div>
				</div>
				<div class="marB10 border flex h80">
					<div class="leftTitle">
						交通计划
					</div>
					<div class="xc-main">
						<el-table :data="jhList" style="width:996px" border :header-cell-style="header">
							<!-- 序号	出发日期	到达日期	出差天数	出行方式	出行人	出发地	到达地	航班号/车次	舱位/席别	国内交通费	 	打车费	出差类型	出差事由	住宿说明	订单号	原订单操作	新订单操作	打卡记录 -->
							<el-table-column type="index" width="50" label="序号"></el-table-column>
							<el-table-column prop="proType" label="行程类型" width="80">
								<template slot-scope="scope">{{ proTypeConfig(scope.row.proType)}}</template>
							</el-table-column>
							<el-table-column prop="against" label="是否违背" width="80">
								<template slot-scope="scope">
									<span v-if="scope.row.against==0">未违背</span>

									<i class="iconfont yj-icon-weizhang" v-else style="font-size:20px;color:red"></i>
								</template>
							</el-table-column>
							<el-table-column prop="departDateLong" label="出发日期" width="130"></el-table-column>
							<el-table-column prop="arriveDateLong" label="到达日期" width="120"></el-table-column>
							<el-table-column prop="departCityName" label="出发城市" width="80"></el-table-column>
							<el-table-column prop="arriveCityName" label="到达城市" width="80"></el-table-column>
							<el-table-column prop="cxr" label="出行人" width="100"></el-table-column>
							<el-table-column prop="flightNo" label="航班号/车次" width="100"></el-table-column>
							<el-table-column prop="cabin" label="舱位/席别" width="80"></el-table-column>
							<el-table-column prop="dcf" label="打车费" width="80"></el-table-column>
							<el-table-column prop="zzsm" label="住宿说明" width="80">
								<template slot-scope="scope">{{ zzsmType(scope.row.zssm) || ''}}</template>
							</el-table-column>
							<el-table-column prop="cclx" label="出差类型" width="80">
								<template slot-scope="scope">{{ scope.row.cclxMc || ''}}</template>
							</el-table-column>
							<el-table-column label="订单号" width="100">
								<template slot-scope="scope">
									<div v-for="(item, index) in scope.row.routeUnionOrderList" :key="index">
										<p style="color:#409EFF" @click="jumpToOrderNo(scope.row,item)">{{item.orderNo}}
										</p>
									</div>
								</template>
							</el-table-column>
							<el-table-column label="原订单操作" width="100">
								<template slot-scope="scope">
									<div v-for="(orderItem, index) in scope.row.routeUnionOrderList" :key="index">
										<span class="color007"
											v-if="!(scope.row.proType == 10904 && orderItem.orderStatus == 14) && orderItem.isShowRefund == 1 && !query.workItemId"
											@click.stop="refund(scope.row,orderItem,'refund')">退票</span>
										<span
											v-if="(scope.row.proType == 10901 && orderItem.refundStatus == 3) || (scope.row.proType == 10903 && orderItem.orderStatus == 9) || (scope.row.proType == 10904 && orderItem.refundStatus == 13)">已退票</span>
										<span class="color007 ml20"
											v-if="!(scope.row.proType == 10904 && orderItem.orderStatus == 14) && orderItem.isShowEndorse == 1 && !query.workItemId"
											@click.stop="ChangeApply(scope.row,orderItem,'change')">改签</span>
										<span class="color007 ml20"
											v-if="(scope.row.proType == 10901 && orderItem.changeStatus == 3) || (scope.row.proType == 10904 && orderItem.refundStatus == 14)">已改签</span>
									</div>
								</template>
							</el-table-column>
							<el-table-column label="新订单操作" width="110">
								<template slot-scope="scope">
									<el-button @click="reserve(scope.row)"
										v-if="dataDetail.status == 2 && scope.row.queryFlag == 1 && scope.row.routeUnionOrderList.length==0 & !query.workItemId">
										快速预订</el-button>
								</template>
							</el-table-column>
							<el-table-column prop="fyys" label="费用预算" width="86"></el-table-column>
							<el-table-column prop="ccsy" label="出差事由" width="100"></el-table-column>
							<el-table-column label="打卡记录" width="110">
								<template slot-scope="scope">
									<span style="color: #808080;" v-if="!scope.row.routeClockInFlag ">未打卡</span>
									<span class="color007" @click="clockIn(scope.row.id)" v-else>已打卡</span>
									<!-- <span class="color007" @click="clockIn(scope.row.id)" >已打卡</span> -->
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="marB10 border flex h80">
					<div class="leftTitle">
						住宿安排
					</div>
					<div class="xc-main">
						<!-- 序号	入住日期	离店日期	入住晚数	入住城市	酒店名称	房型	房间数量	住宿费	 	出差餐费	订单号	原订单操作	新订单操作	打卡记录 -->
						<el-table :data="hotelList" style="width:996px" border :header-cell-style="header">
							<el-table-column type="index" width="50" label="序号"></el-table-column>
							<el-table-column prop="departCityName" label="入住城市" width="80"></el-table-column>
							<el-table-column prop="against" label="是否违背" width="80">
								<template slot-scope="scope">
									<span v-if="scope.row.against==0">未违背</span>

									<i class="iconfont yj-icon-weizhang" v-else style="font-size:20px;color:red"></i>
								</template>
							</el-table-column>
							<el-table-column prop="departDate" label="入住日期" width="130"></el-table-column>
							<el-table-column prop="arriveDate" label="离店日期" width="130"></el-table-column>
							<el-table-column label="入住晚数" width="100">
								<template slot-scope="scope">
									<span>{{routeDiffTime2(scope.row)}}</span>
								</template>
							</el-table-column>
							<el-table-column prop="cxr" label="入住人" width="120"></el-table-column>
							<el-table-column prop="flightNo" label="酒店名称" width="150"></el-table-column>
							<el-table-column prop="cabin" label="房型" width="100"></el-table-column>
							<el-table-column prop="fjs" label="房间数" width="75"></el-table-column>
							<el-table-column prop="fyys" label="住宿费" width="80"></el-table-column>
							<el-table-column prop="cf" label="出差餐费" width="80"></el-table-column>
							<el-table-column label="订单号" width="100">
								<template slot-scope="scope">
									<div v-for="(item, index) in scope.row.routeUnionOrderList" :key="index">
										<p style="color:#409EFF" @click="jumpToOrderNo(scope.row,item)">{{item.orderNo}}
										</p>
									</div>
								</template>
							</el-table-column>
							<el-table-column label="原订单操作" width="100">
								<template slot-scope="scope">
									<div v-for="(orderItem, index) in scope.row.routeUnionOrderList" :key="index">
										<span class="color007"
											v-if="!(scope.row.proType == 10904 && orderItem.orderStatus == 14) && orderItem.isShowRefund == 1 && !query.workItemId"
											@click.stop="refund(scope.row,orderItem,'refund')">退票</span>
										<span
											v-if="(scope.row.proType == 10901 && orderItem.refundStatus == 3) || (scope.row.proType == 10903 && orderItem.orderStatus == 9) || (scope.row.proType == 10904 && orderItem.refundStatus == 13)">已退票</span>
										<span class="color007 ml20"
											v-if="!(scope.row.proType == 10904 && orderItem.orderStatus == 14) && orderItem.isShowEndorse == 1 && !query.workItemId"
											@click.stop="ChangeApply(scope.row,orderItem,'change')">改签</span>
										<span class="color007 ml20"
											v-if="(scope.row.proType == 10901 && orderItem.changeStatus == 3) || (scope.row.proType == 10904 && orderItem.refundStatus == 14)">已改签</span>
									</div>
								</template>
							</el-table-column>
							<el-table-column label="新订单操作" width="110">
								<template slot-scope="scope">
									<el-button @click="reserve(scope.row)"
										v-if="dataDetail.status == 2 && scope.row.queryFlag == 1 && scope.row.routeUnionOrderList.length==0 & !query.workItemId">
										快速预订</el-button>
								</template>
							</el-table-column>
							<el-table-column prop="ccsy" label="出差事由" width="100"></el-table-column>
							<el-table-column label="打卡记录" width="110">
								<template slot-scope="scope">
									<span style="color: #808080;" v-if="!scope.row.routeClockInFlag ">未打卡</span>
									<span class="color007" @click="clockIn(scope.row.id)" v-else>已打卡</span>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="marB10 border flex h80" v-show="changeListData">
					<div class="leftTitle">
						变更记录
					</div>
					<div class="xc-main">
						<!-- 序号	入住日期	离店日期	入住晚数	入住城市	酒店名称	房型	房间数量	住宿费	 	出差餐费	订单号	原订单操作	新订单操作	打卡记录 -->
						<el-table :data="changeListData" style="width:996px" border :header-cell-style="header">
							<el-table-column type="index" width="50" label="序号"></el-table-column>
							<el-table-column label="变更单号">
								<template slot-scope="scope">
									<span class="color007" style="cursor:pointer"
										@click="openShowDialong(scope.row)">{{scope.row.changeNo}}</span>
								</template>
							</el-table-column>
							<el-table-column prop="changeZt" label="变更状态">
								<template slot-scope="scope">{{ changeZtConfig(scope.row.changeZt)}}</template>
							</el-table-column>
							<el-table-column prop="changeYy" label="变更原因"></el-table-column>
							<el-table-column prop="createdate" label="变更时间"></el-table-column>
							<el-table-column label="操作" align="center">
								<template slot-scope="scope">
									<el-button v-if="scope.row.changeZt==1 && scope.row.cancelFlag"
										@click="revokeCcsqd(scope.row.id)">撤回
									</el-button>
									<el-button v-if="scope.row.changeZt==0"
										@click="changeSendApp(scope.row.id)">送审</el-button>
									<el-button v-if="scope.row.changeZt==0" type="danger"
										@click="deleteChange(scope.row.id)">删除
									</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="marB10 border flex h80">
					<div class="leftTitle">
						备注
					</div>
					<div class="xc-main ">
						<div class="xc-text">{{ dataDetail.by1}}</div>
					</div>
				</div>
				<div class="marB10 border flex h80">
					<div class="leftTitle">
						费用合计
					</div>
					<div class="ys-main">
						<div class="xc-ys">
							<span class="bfb-w25">交通预算: ￥{{dataDetail.jtys}}</span>
							<span class="bfb-w25">住宿预算: ￥{{dataDetail.zsys}}</span>
							<span class="bfb-w25">餐饮预算: ￥{{dataDetail.cyys}}</span>
							<span class="bfb-w25">其他预算: ￥{{dataDetail.qtys}}</span>
						</div>
						<div class="xc-ys">
							预计本次出差总预算：￥{{dataDetail.zys}}
						</div>
					</div>
				</div>
				<div class="marB10 border flex h80" v-if="!dataDetail.status ||dataDetail.status == 0">
					<div class="leftTitle">
						审批规则详情
					</div>
					<yj-approval-rule-detail class="sp-main" :detail="gzdetail" :appId="dataDetail.appId">
					</yj-approval-rule-detail>
				</div>

				<div class="marB10 border flex h80" v-if="dataDetail.status>0 && dataDetail.appId">
					<div class="leftTitle">
						审批进度
					</div>
					<yj-approval-progress class="sp-main" :orderId="query.orderId" :orderType="query.orderType"
						:showProgress="showProgress"
						:resetspjd="resetspjd"
						></yj-approval-progress>
				</div>


				<div class="marB10 border flex h80"
					v-if="query.workItemId && dataDetail.status==1 && ifApprover==1 && !outTime">
					<div class="leftTitle">
						是否审批
					</div>
					<div class="sp-main flex">
						<div class="ss-item">
							<div>
								<el-radio label="1" v-model="active">同意</el-radio>
							</div>
							<div>
								<el-radio label="0" v-model="active">拒绝</el-radio>
							</div>
						</div>
						<div class="ss-right">
							<el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="appRemark">
							</el-input>
						</div>
					</div>
				</div>


				<!-- <el-button class="btn" @click="closeHandler">关闭</el-button>
				<el-button class="btn" type="primary" v-if="!query.workItemId" @click="printHandler">打印</el-button>
				<el-button class="btn" v-if="query.workItemId && dataDetail.status==1 && ifApprover==1 && !outTime"
					type="primary" @click="approvalSend">提交</el-button>
				<el-button class="btn" v-if="dataDetail.status==2  && isShowChange" type="primary"
					@click="changeOrder">申请变更 </el-button>
				<el-button class="btn" v-if="dataDetail.status==2  && isShowChange" type="primary" @click="withDraw">取消
				</el-button>
				<el-button class="btn" v-if="dataDetail.status==1  && dataDetail.cancelFlag" type="primary"
					@click="revokeCcsqd()">撤回</el-button>


				<el-button class="btn" style="width: auto;" type="primary" @click="lookCcsqdTpl()">下载申请单模板</el-button> -->


				<yj-approval-cao-zuo 
					:appType="query.appType" 
					:workItemId="query.workItemId" 
					:orderType="11099" 
					:orderId="query.orderId"
					@closePage="spResetDetail" 

					>
					<template slot="orderFooterSlot">
						<div class=" pad20" style="text-align: center;">

							<el-button class="btn" @click="closeHandler">关闭</el-button>
							<el-button class="btn" type="primary" v-if="!query.workItemId" @click="printHandler">打印</el-button>
							<el-button class="btn" v-if="query.workItemId && dataDetail.status==1 && ifApprover==1 && !outTime" type="primary" @click="approvalSend">提交</el-button>
							<el-button class="btn" v-if="dataDetail.status==2  && isShowChange" type="primary" @click="changeOrder">申请变更 </el-button>
							<el-button class="btn" v-if="dataDetail.status==2  && isShowChange" type="primary" @click="withDraw">取消</el-button>
							<el-button class="btn" v-if="dataDetail.status==1  && dataDetail.cancelFlag" type="primary" @click="revokeCcsqd()">撤回</el-button>
							<el-button class="btn" style="width: auto;" type="primary" @click="lookCcsqdTpl()">下载申请单模板</el-button>

						</div>	
					</template>
				</yj-approval-cao-zuo>

			</el-card>
		</div>

		<el-dialog title="打卡记录" :visible.sync="isShowCard" width="30%" center>
			<el-descriptions class="margin-top" title="出发地打卡" :column="4" direction="horizontal">
				<el-descriptions-item label="打卡位置" :span="24">{{clockInDetails.beginAddress}}</el-descriptions-item>
				<el-descriptions-item label="打卡时间" :span="24">{{clockInDetails.beginDate}}</el-descriptions-item>
				<el-descriptions-item label="备注" :span="24">{{clockInDetails.beginRemark}}</el-descriptions-item>
				<el-descriptions-item label="附件">
					<el-image style="width: 100px; height: 100px" :src="clockInDetails.beginImgUrl"></el-image>
				</el-descriptions-item>
			</el-descriptions>
			<el-descriptions class="margin-top" title="到达地打卡" :column="4" direction="horizontal">
				<el-descriptions-item label="打卡位置" :span="24">{{clockInDetails.endAddress}}</el-descriptions-item>
				<el-descriptions-item label="打卡时间" :span="24">{{clockInDetails.endDate}}</el-descriptions-item>
				<el-descriptions-item label="备注" :span="24">{{clockInDetails.endinRemark}}</el-descriptions-item>
				<el-descriptions-item label="附件">
					<el-image style="width: 100px; height: 100px" :src="clockInDetails.endImgUrl"></el-image>
				</el-descriptions-item>
			</el-descriptions>
		</el-dialog>
		<change-dialog :isShow.sync="isShowChangeDialog" :changeId="changeId"> </change-dialog>
	</div>
</template>

<script>
	import {
mapMutations
} from 'vuex';
import changeDialog from './ccsqdModule/ChangeDialog.vue';
import moduleList from './ccsqdModule/moduleList.js';
	export default {
		name: 'CcsqdDetail',
		components: {
			changeDialog,
		},

		data() {
			return {
				header: {
					background: '#FAFAFA',
					height: 40 + 'px',
				},
				minEmpCxr: { //最低职级

				},
				changeId: '',
				clockInDetails: {},
				changeListData: [],
				isShowChangeDialog: false,
				isShowCard: false,
				dataDetail: {}, // 详情信息
				jhList: [], //行程列表
				hotelList: [], // 住宿列表
				carList: [], //用车列表
				approvalInfo: {}, //审批进度信息 
				status: 1, // 审批人 审批高度

				active: '1', // 是否同意
				appRemark: '', // 审批备注
				outTime: false, // 审批是否已过期

				gzdetail: {
					appId: '',
					currentLevel: 1,
					flowState: 3,
				},
				moduleList,
				resetspjd:false,
			}
		},

		computed: {
			// 接收传值
			query() {
				return this.$route.query || {};
			},
			// 
			empInfo() {
				let userInfo = this.$common.sessionget('user_info')
				return userInfo.empInfo;
			},
			isShowChange() { //是否展示变更？
				let show = false
				let myDate = new Date();
				let thisDate = myDate.getFullYear() + "-" + (myDate.getMonth() + 1) + "-" + myDate.getDate();
				if ((this.$common.getDiffDate(thisDate, this.dataDetail.dateEnd) >= 0)) {
					show = true
				} else {
					show = false
				}
				return show
			},
			//当前登录人是否是审批人
			ifApprover() {
				return this.empInfo.ifApprover || 0;
			},
			routeDiffTime() {
				return function(item) {
					const m1 = this.$moment(item.dateBegin);
					const m2 = this.$moment(item.dateEnd);
					const day = m2.diff(m1, 'd');
					return day
				}
			},
			routeDiffTime2() {
				return function(item) {
					const m1 = this.$moment(item.departDate);
					const m2 = this.$moment(item.arriveDate);
					const day = m2.diff(m1, 'd');
					return day
				}
			},

			proTypeConfig() {
				return function(type) {
					let routeConfig = {
						"10904": '火车',
						'10901': '飞机',
						'10903': '酒店',
						'109991': '大巴',
						"109992": '轮船',
						"109993": '自驾',
						"10999": '其他'
					}
					return routeConfig[type]
				}
			},

			changeZtConfig() {
				return function(item) {
					let changeZt = {
						// 变更单状态0草稿；1审批中；2审批通过；3审批拒绝；4已删除 ,
						"0": "草稿",
						"1": "审批中",
						"2": "审批通过",
						"3": "审批拒绝",
						"4": "已删除",

					}
					return changeZt[item]
				}
			},



			zzsmType() {
				return function(type) {
					return this.moduleList.roomsTypeList.filter(item => item.value == type)[0].name
				}
			},
		},
		mounted() {
			this.outTime = (this.query.appType == 3)
			this.getCcsqdInfo();
			// this.ChangeList()

			// this.getOrderAppProgress();
		},
		methods: {
			...mapMutations({

				setTkQuery: 'tk/setTkQuery',
				SET_HTCN_PARAMS: 'htCn/SET_HTCN_PARAMS',
				SETCxrList: 'tk/setCxrList',
				SETTkEdit: "tk/setTkEdit",
				SETChooseFlight: 'tk/setChooseFlight',

				STE_TR_QUERY: 'train/setSearchData',
				SET_TR_TRAIN_DATA: 'train/setTrainData',
				SET_TR_CXR_LIST: 'train/setCxrList',
				SET_TRAIN_YDINFO: 'train/setTrainYdInfo',
				setTrainEdit: 'train/setTrainEdit',

				SAVE_NIGHT: 'htCn/SAVE_NIGHT',
				SAVE_HOTELINFO: 'htCn/SAVE_HOTELINFO',
				SAVE_ROOMINFO: 'htCn/SAVE_ROOMINFO',
				SAVE_DESCRIPTION: 'htCn/SAVE_DESCRIPTION',
				SAVE_PRICE: 'htCn/SAVE_PRICE',
				SAVE_ROOMS: 'htCn/SAVE_ROOMS',
				SAVE_SERVICE_PRICE: 'htCn/SAVE_SERVICE_PRICE',
				COPY_ROOMS: 'htCn/COPY_ROOMS',
				SAVE_NIGHT_ROOMS: 'htCn/SAVE_NIGHT_ROOMS',
				SAVE_ISFREEMEAl: 'htCn/SAVE_ISFREEMEAl',
				SET_HTCN_QUERY: 'htCn/SET_HTCN_QUERY', // 保存因共模式下的自定义信息
				SET_HTCN_CCSQD: 'htCn/SET_HTCN_CCSQD', //保存因共模式下的出差申请单信息
				SET_HT_CXR_LIST: 'htCn/SET_CXR_LIST',


			}),

			spResetDetail(){
				this.$router.replace({
					path: this.$route.path,
					query: {
						...this.$route.query,
						appType:2  // 已审批     0 订单详情 1.待审批 2.已审批 3. 审批过期 
	
					}
				})
				// 审批成功 通知父页面 刷新
				window.opener.needMyApprovalRefresh(1);
				this.getCcsqdInfo();
				this.resetspjd = true;
			},
			printHandler() {
				window.print()
			},
			clockIn(routeId) { // 查看打卡
				let getClockInfo = this.$conn.getConn("xakCcsqd.ClockInDetails");
				getClockInfo({
					routeId: routeId
				}, res => {
					this.clockInDetails = res.data
					this.isShowCard = true
				});

			},
			changeList() {
				this.$conn.getConn('xakCcsqd.fastBookChangeList')({
					ccsqdId: this.dataDetail.id
				}, res => {
					if (res.data) {
						this.changeListData = res.data
					}
				});
			},
			deleteChange(ccsqdId) {
				let param = {
					changeId: ccsqdId,
				}
				this.$confirm('确定要删除吗', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$conn.getConn('travelRequest.travelChangeDel', {
						jsfile: false
					})(param).then((res) => {
						this.$message({
							type: 'success',
							message: '出差申请单删除成功!'
						})
						this.getCcsqdInfo();
					}).catch((err) => {
						this.$message({
							type: 'error',
							message: err.tips
						})
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			changeSendApp(ccsqdId) {
				let param = {
					orderType: "11098",
					orderId: ccsqdId,
					spgzId: this.dataDetail.appId,
				}
				this.$confirm('确定要送审吗', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$conn.getConn('commu.sendApp', {
						jsfile: false
					})(param).then((res) => {
						this.$message({
							type: 'success',
							message: '送审成功!'
						})
						this.getCcsqdInfo();
					}).catch((err) => {
						this.$message({
							type: 'error',
							message: err.tips
						})
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消送审'
					});
				});
			},
			revokeCcsqd(ccsqdId) {
				let param = {
					orderId: !!ccsqdId ? ccsqdId : this.dataDetail.id,
					orderType: !!ccsqdId ? "11098" : "11099",
				}
				this.$confirm('撤回此条申请单？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$conn.getConn('commu.vipBpmSendAppCancel', {
						jsfile: false
					})(param).then((res) => {
						this.$message({
							type: 'success',
							message: '撤回成功！'
						})
						this.getCcsqdInfo();
					}).catch((err) => {
						this.$message({
							type: 'error',
							message: err.tips
						})
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消撤回'
					});
				});
			},
			withDraw() {
				let param = {
					ccsqdId: this.query.orderId,
				}
				this.$confirm('取消此条申请单？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$conn.getConn('xakCcsqd.fastBookCcsqdCancelService', {
						jsfile: false
					})(param).then((res) => {
						this.$message({
							type: 'success',
							message: '撤回成功！'
						})
						this.getCcsqdInfo();
					}).catch((err) => {
						this.$message({
							type: 'error',
							message: err.tips
						})
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消撤回'
					});
				});
			},
			changeOrder() {

				let routerUrl = this.$router.resolve({
					path: '/xakCcsqdChange',
					query: {
						ccsqdId: this.dataDetail.id
					}
				})
				window.open(routerUrl.href, "_blank")

			},
			openShowDialong(item) {
				this.isShowChangeDialog = true
				this.changeId = item.id
			},
			// isShowChangeDialog=true

			// fastBookChangeList
			closeHandler() {
				this.$confirm('此操作将关闭本页面, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					window.close();
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
			},
			jumpToOrderNo(value, item) { //跳转订单详情
				let query = {};
				let path;
				if (value.proType == '10901') {
					path = '/yjDetail/tkOrderDetail'
					query = {
						orderId: item.orderId,
						orderType: 11099
					}
				} else if (value.proType == '10904') {
					path = '/yjDetail/trainDetail'
					query = {
						orderId: item.orderId,
						orderType: 11099
					}
				} else if (value.proType == '10903') {
					path = '/yjDetail/htCnOrderDetail'
					query = {
						orderId: item.orderId,
						orderType: 11099
					}
				}
				let routeUrl = this.$router.resolve({
					path,
					query: query
				});
				window.open(routeUrl.href, '_blank')
			},

			refund(route, orderItem) { //退！
				let orderType = route.proType;
				let orderTypeObj = {
					10901: '/yjEdit/tkRefund',
					10903: '/yjTravel/book/htCnRouter/htRefundRooms',
					10904: '/yjEdit/trainRefund'
				}
				let path = orderTypeObj[orderType]
				console.log(path, "path")
				let query = {
					orderId: orderItem.orderId,
					orderType: 11001,
					refundFlag: 1
				};
				if (path) {
					// 此处酒店单独处理
					if (path == "/yjTravel/book/htCnRouter/htRefundRooms") {
						this.$confirm('酒店仅支持全退，如需部分退请联系客服人员!', '温馨提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							let routeUrl = this.$router.resolve({
								path: path,
								query: query
							});
							window.open(routeUrl.href, "_blank")
						}).catch(() => {
							this.$message({
								type: 'info',
								message: '已取消'
							});
						});

					} else {
						let routeUrl = this.$router.resolve({
							path: path,
							query: query
						});
						window.open(routeUrl.href, "_blank")
					}
				} else {
					this.$message({
						type: 'error',
						message: '该订单无法进行退票,如需退票，请联系客服处理！'
					})
				}
			},

			ChangeApply(route, orderItem) { //改签
				let orderType = route.proType;
				console.log(orderItem, "orderItem")
				let orderTypeObj = {
					10901: '/yjEdit/tkChange',
					10904: '/yjEdit/trainChange'
				}
				let query = {
					orderId: orderItem.orderId,
					changeFlag: 1,
					orderType: orderType,
					tripType: 1,
				};
				let routeUrl = this.$router.resolve({
					path: orderTypeObj[orderType],
					query: query
				});
				window.open(routeUrl.href, "_blank")
			},

			// 获取最低员工职级
			getMinEmpRank() {
				let cxrArr = this.dataDetail.fastDetailsPersonList || [];
				let minEmpRank = 1000;
				for (let i = 0; i < cxrArr.length; i++) {
					let cxr = cxrArr[i];
					if (cxr.sxr == 0) {
						minEmpRank = cxr.empRank;
						this.minEmpCxr = cxr;
					}
				}
				console.log(this.minEmpCxr, "this.minEmpCxr")
			},
			xakSetfastDetailsPersonList(cxrId) { // 修改vuex中预定人数据源
				let cxrIdList = cxrId.split(",")
				console.log(cxrIdList)
				let arr = []
				this.dataDetail.fastDetailsPersonList.forEach(item => {
					cxrIdList.forEach(i => {
						if (item.empId == i) {
							arr.push(item)
						}
					})
				})
				let crxList = arr
				crxList.forEach(item => {
					var idCard0 = item.fastIdCardDetailList[0] || {};
					item.useCardType = 1; //证件类型默认取身份证
					item.costCenterId = this.dataDetail.costCenterId;
					item.costCenterCode = this.dataDetail.costCenterCode;
					item.costCenterName = this.dataDetail.costCenterName;
					item.psgName = item.empName || item.cxrName;
					item.psgId = item.contactsId || item.empId;
					item.empName = item.cxrName
					var idCardList = this.$common.deepCopy(item.fastIdCardDetailList);
					item.vipIdcardResponses = idCardList;
					item.idcardList = idCardList;
					item.cxrType = item.exPerson == 0 ? 1 : 2
					item.personId = item.id
				})
				return crxList
			},
			reserve(item) {
				// console.log(item,this.dataDetail,"item")
				// path: '/yjEdit/tkEdit',
				//快速预定

				let myDate = new Date();
				let thisDate = myDate.getFullYear() + "-" + (myDate.getMonth() + 1) + "-" + myDate.getDate();
				if (!(this.$common.getDiffDate(thisDate, item.departDate) >= 0)) {
					this.$alert('该行程预订日期已过期，无法快速预订行程');
				} else {
					let sessionObj = {
						routeId: item.id
					}
					this.$common.sessionset('chooseCcsqdObj', sessionObj)
					if (item.proType == '10901') {
						let extInfo = JSON.parse(item.extendInfo) || {};
						item = {
							...item,
							extInfo
						};
						console.log(item, "itemitemitem")
						this.setTkQuery({
							tripType: 1, // 因公因私
							travelType: 1, // 单程-1 往返-2
							tkType: 0, // 1-政采票
							depszm: item.departAirport,
							dep: item.departCityName,
							arrszm: item.arriveAirport,
							arr: item.arriveCityName,
							depDate: item.departDate,
							bookStyle: 2, // 出差单模式-2
							fyys: item.fyys, // 费用预算
							ccsqdId: this.dataDetail.id,
							ccsqdNo: this.dataDetail.ccsqdNo,
							routeId: item.id,
							empRank: this.minEmpCxr.empRank,
							useNameclbz: this.minEmpCxr.empName
						})
						let obj = {
							airportFee: item.extInfo.airportFee,
							tax: item.extInfo.tax,
							...item,
							...item.extInfo,
							chooseCabin: {
								cabinType: item.extInfo.cabinType || '',
								violateCode: item.violateitemCode || '',
								violateitem: item.violateitem || '',
								reasonDesc: item.wbyy || '',
								reasonCode: item.reasonCode || '',
								fcny: item.fyys,
								salePrice: item.dj,
								...item.extInfo
							}
						}

						let tkEditObj = {
							project: {
								id: item.cclx,
								projectCode: item.cclxCode,
								projectName: item.cclxMc,
							}
						}
						this.SETChooseFlight(obj)
						this.SETCxrList(this.xakSetfastDetailsPersonList(item.cxrId))
						this.SETTkEdit(tkEditObj)

						let routeUrl = this.$router.resolve({
							path: '/yjEdit/tkEdit',
							query: {
								sqdType: 'xak'
							}
						});
						window.open(routeUrl.href, "_blank")
					} else if (item.proType == '10904') {
						this.STE_TR_QUERY({
							tripType: 1, // 因公因私
							travelType: 1, // 单程-1 往返-2
							tkType: 0, // 1-政采票
							depszm: item.departAirport,
							dep: item.departCityName,
							arrszm: item.arriveAirport,
							arr: item.arriveCityName,
							depDate: item.departDate,
							bookStyle: 2, // 出差单模式-2
							fyys: item.fyys, // 费用预算
							ccsqdId: this.dataDetail.id,
							ccsqdNo: this.dataDetail.ccsqdNo,
							routeId: item.id,
							empRank: this.minEmpCxr.empRank,
							useNameclbz: this.minEmpCxr.empName,
							violateCode: item.violateitemCode || '',
							violateItem: item.violateitem || '',
							reasonDesc: item.wbyy || '',
							reasonCode: item.reasonCode || '',
							weiType: 1,
						})
						let trEditObj = {
							project: {
								id: item.cclx,
								projectCode: item.cclxCode,
								projectName: item.cclxMc,
							}
						}
						this.setTrainEdit(trEditObj)

						this.SET_TR_CXR_LIST(this.xakSetfastDetailsPersonList(item.cxrId))
						this.SET_TRAIN_YDINFO(item)
						let routeUrl = this.$router.resolve({
							path: '/yjEdit/TrEdit',
							query: {
								sqdType: 'xak',
								routeId: item.id
							}
						});
						window.open(routeUrl.href, "_blank")
					} else if (item.proType == '10903') {
						// extendInfo 只是扩展字段， 没有什么信息
						// let extendInfo=item.extendInfo? JSON.parse(item.extendInfo) : {}  

						let planSearch = {
							checkInDate: item.departDate,
							checkOutDate: item.arriveDate,
							orderFrom: 10506,
							hotelId: item?.jdid ?? '',
							rooms: item.fjs,
							routeId: item.id,
							fyid: item?.fyid ?? '',
							roomId: item?.cabinCode ?? '',
							ratePlanId: item?.priceId ?? '',
							payment: 2
						}
						let hotelCheckAvail = this.$conn.getConn('xakCcsqd.hotelCheckAvail', );
						hotelCheckAvail(planSearch, res => {
							this.setHtInfo(item)
						}, err => {
							this.$alert(err.tips)
						})

					}
				}

			},

			setHtInfo(item) {
				let extendInfo = JSON.parse(item.extendInfo) || {}
				this.$conn.getConn('htbook.planSearch')({
						checkInDate: item.departDate,
						checkOutDate: item.arriveDate,
						fyid: item.fyid,
						hotelId: item.jdid,
						payment: item.payment,
						ratePlanId: item.priceId,
						roomId: item.cabinCode,
					}).then((res) => {
						let rommsObj = res?.data?.rooms[0]
						this.$conn.getConn('htbook.hotelRoom')({
							hotelId: item.jdid,
							findFrom: 1,
							roomId: item.cabinCode,
						}, (res) => {
							let resRooms = res.data;
							resRooms.forEach(item => {
								if (rommsObj.roomId == item.fxid) {
									rommsObj.resRoomInfo = item
								}
							})
							this.SAVE_ROOMINFO(rommsObj)
							this.SAVE_HOTELINFO({
								img: item.img,
								address: item.address,
								jdmc: item.jdmc,
							})
						})
						this.SAVE_ISFREEMEAl(res?.data?.rooms[0]?.ratePlans[0]?.freeMeal ?? 0)
						this.SAVE_NIGHT_ROOMS(res?.data?.rooms[0]?.ratePlans[0]?.nightlyRates ?? [])
						this.SAVE_DESCRIPTION(res?.data?.rooms[0]?.ratePlans[0]?.prepayRule?.description ?? '暂无取消规则！')
						this.SET_HTCN_QUERY({
							empRank: this.minEmpCxr.empRank,
							useNameclbz: this.minEmpCxr.empName,
							violateCode: item.violateitemCode || '',
							violateItem: item.violateitem || '',
							reasonDesc: item.wbyy || '',
							reasonCode: item.reasonCode || '',
							weiType: 1,
						})
						this.SAVE_ROOMS(item.fjs)

						this.SET_HT_CXR_LIST(this.xakSetfastDetailsPersonList(item.cxrId))
						let obj = {
							checkInDate: item.departDate,
							checkOutDate: item.arriveDate,
							jdid: item.jdid,
							roomId: item.cabinCode,
							payment: item.payment,
							ratePlanId: item.priceId,
							fyid: item.fyid,
							hotelId: item.jdid,
							bussType: item.bussType,
							sqdType: 'xak'
						}
						this.$router.push({
							name: 'HtCnEdit',
							query: obj,
						})
					})
					.catch((err) => {
						console.log(err, "err")
						this.$message({
							type: 'error',
							message: '该时间段内，酒店已满房，暂无报价！请重新选择日期，或者选择其他房型！',
						})
					})
			},

			// 出差申请单详情
			getCcsqdInfo() {
				let queryParams = {};
				queryParams = {
					id: this.query.orderId
				}
				this.$conn.getConn('xakCcsqd.xakVipCcsqdGetInfo', {
					jsfile: false
				})(queryParams).then((res) => {
					let data = res.data || {};
					this.dataDetail = data;
					let manyMdd = '';
					this.jhList = [];
					this.hotelList = [];
					this.getMinEmpRank()
					this.changeList()
					data?.fastDetailsRouteList?.forEach(element => {
						if (element.ccsy) {
							manyMdd += element.ccsy
						}
						if (element.proType != 10903) {
							this.jhList.push(element)
						} else {
							this.hotelList.push(element)
						}
					});
					this.dataDetail.manyMdd = manyMdd || ''
				}).catch((err) => {
					this.$message({
						type: 'error',
						message: err.tips
					})
				})
			},
			//审批
			approvalSend() {
				if (this.active == 0 && !this.appRemark) {
					this.$message({
						message: '审批拒绝需要填写拒绝原因',
						type: 'info'
					})
				} else {
					let queryParams = {};
					queryParams = {
						appRemark: this.appRemark,
						completeState: this.active,
						completeWay: 1,
						orderId: this.query.orderId,
						workItemId: this.query.workItemId,
					}
					this.$conn.getConn('commu.myAppDetail', {
						jsfile: false
					})(queryParams).then((res) => {
						this.$message({
							type: 'success',
							message: '审批成功'
						})
						// 审批成功 通知父页面 刷新
						window.opener.needMyApprovalRefresh(1);
						setTimeout(() => {
							this.spClose();
						}, 500)
					}).catch((err) => {
						this.$message({
							type: 'error',
							message: err.tips
						})
					})
				}

			},
			showProgress(info, currentSpjd, status) {
				// 进度
				this.spjd = info.spjd; //当前审批进度（1/2）
				this.gzdetail.currentLevel = currentSpjd; //当前到谁审批
				this.gzdetail.flowState = status; // 当前审人状态

			},
			// 关闭审批
			spClose() {
				window.close();
			},
			
			
			// 查看出差申请单模板
			// lookCcsqdTpl(){
			// 	this.$router.push({
			// 		path:"/tpl/ccsqdTpl",
			// 		query:{
			// 			orderId:this.query.orderId,
			// 			orderType:this.query.orderType
			// 		}
					
			// 	})
			// },
			// 希安康出差申请单模板打印
			lookCcsqdTpl:function(){
				let self = this;
				var queryObjForCreateCcsqdPdf = {
					// id: this.query.orderId,
					corpId:this.dataDetail.corpId,
					travelNo:this.dataDetail.ccsqdNo,
				}
				var createCcsqdPdf = this.$conn.getConn("xakCcsqd.testcreateCcsqdPdf")
				createCcsqdPdf(queryObjForCreateCcsqdPdf,function (resp) {
					var data = resp.data ;
					if(data){
						self.downloadFileByBase64(data,self.dataDetail.ccsqdNo,'application/pdf')
					}
				},function(err){
					
				})
			},
			/// 将base64 转化为 文件并下载
			 downloadFileByBase64:function(base64, name ,fileType) {
				 //  将base64 转换为 blob 流
			      var myBlob = this.dataURLtoBlob(base64,fileType);
				  // var myBlob = this.base64ToBlob(base64);
				  // URL  将blob流转化为文件
			      var myUrl = URL.createObjectURL(myBlob);
				  // 下载文件
			      // this.downloadFile(myUrl, name);
				  // 打开文件
				  window.open(myUrl)
			},
			// 仅含base64文件的
			 base64ToBlob:function (code,fileType) {
			      code = code.replace(/[\n\r]/g, '')
			      const raw = window.atob(code)
			      const rawLength = raw.length
			      const uInt8Array = new Uint8Array(rawLength)
			      for (let i = 0; i < rawLength; ++i) {
			        uInt8Array[i] = raw.charCodeAt(i)
			      }
			      return new Blob([uInt8Array], { type:fileType  })
			},
			// 处理含文件类型的情况
			base64ToBlobTwo:function (codeArr,fileType) {
				let mimeStr = codeArr[0] || '';
				let codeStr = codeArr[1] || '';
				let mimeArr = mimeStr.match(/:(.*?);/) || [];
				let    mime = mimeArr[1];
				codeStr = codeStr.replace(/[\n\r]/g, '');
				let    bstr = window.atob(codeStr);
				let    n = bstr.length;
				let    u8arr = new Uint8Array(n);
					
					while (n--) {
						u8arr[n] = bstr.charCodeAt(n);
					}
				return new Blob([u8arr], { type: mime || fileType });
			},
			
			// //将base64转换为blob
			dataURLtoBlob:function(dataurl,fileType) {
				dataurl = dataurl.replace(/[\n\r]/g, '')
			    var arr = dataurl.split(",") || [];
			    if(arr.length == 1){
				    return 	this.base64ToBlob(arr[0] || '',fileType);
				}else{
					return  this.base64ToBlobTwo(arr);
				}   
			},
			// 下载文件
			downloadFile:function(url, name){
				var a = document.createElement("a");
				a.setAttribute("href", url);
				a.setAttribute("download", name);
				a.setAttribute("target", "_blank");
				let clickEvent = document.createEvent("MouseEvents");
				clickEvent.initEvent("click", true, true);
				a.dispatchEvent(clickEvent);
			},
		}
	}
</script>

<style scoped lang="scss">
	.ad-main-c {
		background-color: #EEF1F6;
		padding: 40px 0;

		.main {
			margin: 0 auto;
			width: 1200px;

			.flex {
				display: flex;
			}

			.color007 {
				color: #007aff;
			}

			.between {
				justify-content: space-between;
			}

			.marB10 {
				margin-bottom: 10px;
			}

			.border {
				border: 1px solid #E6E6E6;
			}

			.h80 {
				min-height: 80px;
			}

			.ml20 {
				margin-left: 20px;
			}

			.btn {
				width: 80px;
				height: 36px;
				font-size: 14px;
			}

			.leftTitle {
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: #FAFAFA;
				border-right: 1px solid #E6E6E6;
				width: 120px;
			}

			.padTB30 {
				padding: 30px 0;
			}

			.sp-main {
				width: 1036px;
				padding: 20px 0 20px 40px;

				.ss-item {
					text-align: left;
					line-height: 30px;
					width: 100px;
				}

				.ss-right {
					width: 700px;
				}

				.sp-item {
					font-size: 14px;
				}
			}

			.ys-main {
				width: 1036px;
				text-align: left;
				padding: 15px 0;

				.xc-ys {
					padding-left: 40px;
					line-height: 40px;

					.bfb-w25 {
						width: 25%;
						display: inline-block;
					}
				}
			}

			.xc-main {
				width: 1036px;
				text-align: left;
				padding: 20px 40px;

				.xc-text {
					padding: 24px 0;
					line-height: 30px;
				}

				.bfb-w5 {
					width: 5%;
				}

				.bfb-w8 {
					width: 8%;
				}

				.bfb-w10 {
					width: 10%;
				}

				.bfb-w20 {
					width: 20%;
				}
			}

			.right-main {
				width: 1036px;
				flex-wrap: wrap;
				padding: 10px 0;

				.topItem {
					width: 33%;
					text-align: left;
					padding-left: 40px;
					line-height: 30px;
				}

				.title-width {
					width: 80px;
					display: inline-block;
				}
			}
		}
	}
</style>